import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import React from "react";

const About = () => {
  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-gradient-to-b from-white to-[#f0f4f2] py-20 px-5 md:px-20">
      {/* Heading Section */}
      <div className="text-center mb-14">
        <h1 className="text-[#5b8251] text-6xl md:text-7xl font-extrabold relative inline-block">
          ABOUT US
          <span className="block absolute w-32 h-3 bg-[#5b8251] left-1/2 transform -translate-x-1/2 -translate-y-10 rounded-md"></span>
        </h1>
      </div>

      {/* Content Section */}
      <div className="max-w-5xl mx-auto text-xl md:text-2xl leading-relaxed text-gray-800 space-y-8">
        <p className="font-bold text-2xl md:text-3xl text-[#5b8251]">
          At Eco Green Project, we are committed to transforming the way you harness energy.
        </p>
        <p>
          Founded by Sebastien, who fell in love with the breathtaking landscapes of Calp, our mission is to empower locals and expats to embrace sustainable living through solar energy solutions.
        </p>
        <p>
          Imagine reducing your electricity bill by an incredible 85%! Our solar panel installations are not only a smart choice, they are a game changer for your wallet and the environment.
        </p>
        <p>
          For over 15 years, we have been well established in the solar panel industry in Belgium, having successfully completed a number of installations. Today, we bring our expertise to the sunny coasts of Calp, Altea, Moraira, Benissa, and beyond. Our full range of services includes:
        </p>

        <ul className="list-disc ml-10 space-y-4">
          <li>Solar panel installation: maximize your energy savings with our cutting-edge solar technology.</li>
          <li>Air conditioning solutions: keep your home cool and comfortable during the hot summer months.</li>
          <li>Pool Heat Pumps: Enjoy your pool all year round with energy-efficient heating solutions.</li>
          <li>Thermodynamic Hot Water Tanks: Harness renewable energy for your hot water needs.</li>
          <li>Electric Terminals: Ensure your home is equipped with the latest electrical solutions.</li>
        </ul>

        <p>
          Join us in our commitment to creating an eco-friendly environment while saving money. Let Eco Green Project guide you on your journey to sustainable living. Contact us today to find out how we can help you switch to solar energy and enjoy the benefits of a greener lifestyle!
        </p>
        <p className="font-bold text-[#5b8251] text-3xl">
          Eco Green Project: Save Money, Save the Planet.
        </p>
      </div>
    </div>
      <Footer />
    </>
  );
};

export default About;
