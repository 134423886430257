
export const data = {
    rows: [
        {
            title: "Tell me about GreenTech Enterprises?",
            content: `GreenTech Enterprises is committed to providing sustainable and eco-friendly energy solutions to our customers. We specialize in solar panel installations, energy-efficient solutions, and renewable energy system maintenance. Our team is dedicated to delivering innovative, reliable, and sustainable services with the goal of reducing carbon footprints and promoting clean energy use. We ensure top-quality workmanship and prioritize customer satisfaction on every project we undertake.`,
        },
        {
            title: "Why should we hire you?",
            content: `There are three key reasons why you should hire us:
            <ul>
                <li>1. We are passionate about renewable energy and consistently deliver high-quality projects that meet or exceed client expectations.</li>
                <li>2. We bring extensive expertise in solar panel installations and energy-efficient systems, ensuring that our solutions are optimized for performance and sustainability.</li>
                <li>3. We are result-driven and aim to provide cutting-edge technology that supports your business in reducing energy costs and achieving sustainability goals.</li>
            </ul>
            `,
        },
        {
            title: "How do you handle negative feedback from a client?",
            content: `We value feedback as an opportunity for growth and improvement. We carefully analyze any concerns, address them promptly, and take action to ensure that our clients' needs and expectations are fully met.`,
        },
        {
            title: "If you have multiple tasks but realize you can't meet the deadline, what would you do?",
            content: `We would prioritize tasks based on their impact on the project and communicate transparently with the client about possible delays. If necessary, we would request additional time to ensure that all tasks are completed with the highest quality.`,
        },
        {
            title: "What is your work process?",
            content: `Our work process involves assessing your energy needs, designing customized solar solutions, installing the system with precision, and providing ongoing maintenance. We ensure that all installations comply with industry standards and regulations while maximizing energy output and efficiency.`,
        },
        {
            title: "What type of projects do you usually work on?",
            content: `We typically work on projects related to renewable energy solutions, including residential and commercial solar panel installations, energy storage systems, and smart grid integration. Our projects are designed to reduce energy consumption, lower costs, and support the transition to sustainable energy.`,
        },
        {
            title: "What is the best way to contact you?",
            content: `The best way to reach us is through email or by phone. You can also find us on social media, where we regularly share updates on our projects and eco-friendly energy solutions.`,
        },
    ],
};

export const project = [
    {
        id: 1,
        alt: 'Before and after images of a solar panel installation on a residential property, showing the transformation from traditional energy use to a fully renewable system.',
        title: 'Residential Solar Panel Installation',
        status: 'Completed',
        description: 'This project involved the installation of a high-efficiency solar panel system for a residential property. The system significantly reduced the homeowner’s reliance on non-renewable energy sources and resulted in lower energy costs.',
        highlights: ['Installation of high-efficiency solar panels', 'Inverter setup for optimal energy conversion', 'Full system integration and testing'],
        detail: 'The Residential Solar Panel Installation project was a success, with the homeowner now enjoying reduced energy bills and contributing to a cleaner environment. Our team completed the project on time, ensuring that all components were installed safely and that the system operates at peak efficiency.',
    },
    {   
        id: 2,
        alt: 'Solar panels installed on a commercial building, optimizing energy use and reducing the carbon footprint of the business.',
        title: 'Commercial Solar Energy Project',
        status: 'Completed',
        description: 'A large-scale solar panel installation for a commercial building aimed at significantly reducing energy costs and the building’s environmental impact.',
        highlights: ['Design of solar system to meet business needs', 'Installation of panels and energy storage system', 'Monitoring system for energy output'],
        detail: 'The Commercial Solar Energy Project successfully provided the client with a renewable energy solution that drastically cut operational energy costs while promoting sustainability. Our team ensured compliance with local energy regulations and delivered the project within the allocated time and budget.',
    },
    {
        id: 3,
        title: 'Solar Battery Storage Installation',
        status: 'In Progress',
        alt: "A solar battery storage system being installed to store excess energy generated by solar panels, ensuring continuous power supply even during grid outages.",
        description: 'This project focuses on installing an energy storage system for a residential property, allowing the homeowner to store excess solar energy for use during off-peak hours or in case of power outages.',
        highlights: ['Installation of solar battery storage system', 'Integration with existing solar panel setup', 'Testing and commissioning'],
        detail: 'The Solar Battery Storage Installation project aims to provide homeowners with a reliable backup energy source and enhance the efficiency of their solar energy system. Once completed, the project will increase energy independence and reduce reliance on the grid.',
    },
    {
        id: 4,
        alt: "Solar panels installed on a mini estate to provide renewable energy to multiple units, promoting sustainability and reducing energy costs.",
        title: 'Mini Estate Solar Power Installation',
        status: 'Completed',
        description: 'This project involved the installation of solar panels across multiple units in a mini estate, providing clean, renewable energy to the entire community.',
        highlights: ['Design and layout for optimal energy generation', 'Installation of solar panels for each unit', 'System monitoring for performance optimization'],
        detail: 'The Mini Estate Solar Power Installation successfully provided eco-friendly energy solutions for all units, reducing energy bills and environmental impact for the estate’s residents. The project was completed efficiently and within budget, exceeding the client’s expectations.',
    },
    {
        id: 5,
        alt: "A building retrofitted with solar panels and modern energy-efficient solutions, contributing to reduced energy consumption and operational costs.",
        title: 'Building Renovation with Solar Energy Retrofit',
        status: 'Completed',
        description: 'A renovation project that included retrofitting the building with solar panels and energy-efficient systems to reduce its environmental impact and energy consumption.',
        highlights: ['Assessment and planning for solar panel retrofit', 'Installation of energy-efficient systems', 'Testing and inspection of all components'],
        detail: 'The Building Renovation with Solar Energy Retrofit improved the energy efficiency of the building, leading to significant reductions in energy costs and a smaller carbon footprint. The project highlights the importance of integrating renewable energy solutions in existing infrastructure.',
    },
    {
        id: 6,
        alt: "Newly installed solar panels and energy storage system in a building, providing sustainable energy and reducing reliance on the grid.",
        title: 'New Building Solar Installation',
        status: 'Completed',
        description: "Installation of a complete solar energy system in a new building, including solar panels and an energy storage solution to optimize energy use.",
        highlights: ['Design and installation of the solar energy system', 'Integration with energy storage', 'Thorough testing and inspection'],
        detail: 'The New Building Solar Installation project provided the building with a sustainable energy source that reduces reliance on the grid and promotes energy efficiency. The system was installed on time and within budget, ensuring high performance and reliability for the building’s occupants.',
    }
];
