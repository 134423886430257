import React from "react";
import './Services.css';
import {Link } from "react-router-dom";
import solar from "../assets/solarpanel.webp";
import air from "../assets/airconditioning3.svg";
import pool from "../assets/waterheater.svg";
import heater from "../assets/heater3.svg";
import electric from "../assets/electriccar3.svg";
import solarinstallation from "../assets/solarpanelinstallation.jpg";
import heaterinstallation from "../assets/heaterinstallation.jpg";
import electricinstallation from "../assets/electricinstallation.jpg";

const Services = () => {
  return (
    <>
    <section className="services-section">
      {/* Icons section */}
      <div className="service-icons">
        <div className="service-icon">
            <a href="">
                <img src={solar} alt="Solar Panel" />
                <p>Solar Panel Installation</p>
            </a>
        </div>
        <div className="service-icon">
            <a href="">
                <img src={air} alt="Air Conditioning" />
                <p href="">Air Conditioning Installation</p>
            </a>
        </div>
        <div className="service-icon">
            <a href="">
                <img src={pool} alt="Pool Heat Pump" />
                <p href="">Pool Heat Pump Installation</p>
            </a>
        </div>
        <div className="service-icon">
            <a href="">
                <img src={heater} alt="Heat Pump Boiler" />
                <p href="">Heat Pump Boiler Installation</p>
            </a>
        </div>
        <div className="service-icon">
            <a href="">
                <img src={electric} alt="Charging Station" />
                <p href="">Electric Car Charging Station</p>
            </a>
        </div>
      </div>
    
      {/* View all services button */}
      <div className="view-all-btn">
        <Link to="/result"><button>View Our Services</button></Link>
      </div>
    </section>

      {/* Services with background images */}
      <div className="service-backgrounds">
        <div 
          className="service-item" 
          style={{ backgroundImage: `url(${solarinstallation})` }}
        >
          <h3>Friendly & Personal Service</h3>
        </div>
        <div 
          className="service-item" 
          style={{ backgroundImage: `url(${heaterinstallation})` }}
        >
          <h3>Wealth of Experience in Installation & Maintenance</h3>
        </div>
        <div 
          className="service-item" 
          style={{ backgroundImage: `url(${electricinstallation})` }}
        >
          <h3>Available 24/7</h3>
        </div>
      </div>
    </>
  );
};

export default Services;
