import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Testimonial from "../components/Testimonial";
import { feedback } from '../functions/customerFeedback';
import Choose from '../components/Choose';
import About from "../components/about";
import Services from "../components/Services";
import Whoarewe from "../components/Whoarewe";
import { NavLink } from "react-router-dom";
import bg from "../logo/bg.jpg";
import bg1 from "../logo/bg1.jpg";
import bg2 from "../logo/bg2.jpg";

const Home = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [bg, bg1, bg2]; // The images array that rotates

  // Function to change background image every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // 5000ms or 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [images.length]);

  return (
    <>
      <Navbar />
      <div className="relative">
        {/* Background Image Section */}
        <div className="relative">
          <div className="py-24 z-50 absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center text-white text-center">
            <h1 className="text-[44px] md:text-[64px] font-bold my-4 animate__animated animate__zoomIn">
            Eco Green Projects
            </h1>
            <p className="text-[20px] md:text-[26px] mb-3">
            Save Money, Save the Planet.. 
            </p>
            <div className="flex gap-5">
              <NavLink to="/message">
                <button className="p-3 font-bold px-8 border border-[#5b8251] bg-[#5b8251] rounded-md hover:bg-[#73988d] hover:border-[#73988d] active:bg-[#6fb962] active:border-[#6fb962] duration-100">
                  CONTACT US
                </button>
              </NavLink>
              <NavLink to="/result">
                <button className="p-3 font-bold px-8 border-2 border-white bg-transparent rounded-md hover:bg-[#73988d] hover:border-[#73988d] active:bg-[#6fb962] active:border-[#6fb962] duration-100">
                  OUR SERVICES
                </button>
              </NavLink>
            </div>
          </div>

          {/* Image Rotation with Inline Styles */}
          <img
            className="h-[620px] w-full object-cover transition-opacity duration-1000 ease-in-out"
            style={{
              opacity: 1,
              transition: "opacity 1s ease-in-out",
            }}
            src={images[currentImageIndex]} // Correct reference to the `images` array
            alt="Plumbing background"
          />
          <div className="absolute bg-[#0f2b5b99] top-0 left-0 right-0 bottom-0" />
        </div>
        <div className="absolute top-50 bottom-0 left-0 right-0 z-50">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#fff"
              fillOpacity="1"
              d="M0,224L48,218.7C96,213,192,203,288,186.7C384,171,480,149,576,165.3C672,181,768,235,864,266.7C960,299,1056,309,1152,293.3C1248,277,1344,235,1392,213.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
        </div>
      </div>
      <div className="bg-white shadow md:mx-5 rounded-md py-8 my-5">
        <About />
      </div>
      <div className="bg-white shadow md:mx-5 rounded-md py-8 my-5">
        <Services />
      </div>
      <div className="bg-white shadow md:mx-5 rounded-md py-8 my-5">
        <Whoarewe />
      </div>
      
      <div>
        <Choose />
      </div>

      <div className="py-5 bg-white shadow rounded-md my-5 md:mx-5 px-3 md:px-0">
        <div className="text-center flex flex-col space-y-2 my-6">
          <h2 className="text-zinc-600 font-bold text-[30px]">TESTIMONIALS</h2>
          <p className="font-bold text-[20px] md:text-4xl text-[#5b8251]">
            What clients say
          </p>
          <p className="max-w-sm md:max-w-lg mx-auto font-tin text-sm text-zinc-600 py-2">
            We place huge value on strong relationships and have seen the
            benefit they bring to our business. Customer feedback is vital in
            helping us to get it right.
          </p>
        </div>
        <div className="grid md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-x-5 my-8 gap-y-5 justify-center bg-white p-10 md:mx-0 rounded-md">
          {feedback.map((feed, index) => (
            <Testimonial
              key={index}
              image={feed.image}
              alt={feed.alt}
              message={feed.message}
              name={feed.name}
              prof={feed.prof}
            />
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
