import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Result from './pages/Result'
import About from './pages/About'
import Faqs from './pages/Faqs'
import Appointment from './pages/Appointment'
import { Helmet } from "react-helmet";
import ContactButton from './functions/Contactbutton';

function App() {
  return (
    <>
      <Helmet>
        <title>ECO GREEN PROJECTS</title>
        <meta name="description" content="Kasbass Plumbing Services offers expert plumbing services in Lagos, Nigeria. Contact us today for a free quote!" />
        <meta name="keywords" content="plumbing services, Lagos, Nigeria, Kasbass Plumbing Services |have knowledgeable and experienced technicians, offer competitive pricing, and use high-quality materials. |Experience the Best Plumbing Services in Nigeria with Be Plumbing Services!" />
        <meta name="author" content="Kasbass Plumbing Services" />
      </Helmet>
       <div className="box-sizing:border-box bg-gray-100">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/result" element={<Result />} />
        <Route path="/about" element={<About />} />
        <Route path="/" element={<Home />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/appointment" element={<Appointment />} />
      </Routes>
      <ContactButton />
    </div>
    </>
  );
}

export default App;
