import React from "react";
import { NavLink } from "react-router-dom";
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/solid';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import ecologo from "../logo/ecologo.png";

const Navbar = () => {
  return (
    <header>
      <div className="flex">
        <div className="bg-[#5b8251] w-1/3 p-3 flex items-center px-5">
          <span className="text-white font-bold text-[13px] md:text-[18px]">ECO GREEN PROJECTS</span>
        </div>
        <div className="bg-[#73988d] w-2/3 p-3 flex justify-end items-center md:pr-20">
          <div className="flex space-x-3">
            <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
              <FaFacebook className="text-white h-6 w-6" />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noreferrer">
              <FaTwitter className="text-white h-6 w-6" />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
              <FaInstagram className="text-white h-6 w-6" />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">
              <FaLinkedin className="text-white h-6 w-6" />
            </a>
          </div>
        </div>
      </div>
      <div className="flex justify-center md:justify-between items-center py-2 px-3 md:px-10 bg-white">
      <div className="md:flex md:flex-col items-baseline hidden">
           <NavLink to="/" className="link flex items-center">
             <img src={ecologo} alt="Company Logo" className="h-16 w-16 md:h-24 md:w-24" />
           </NavLink>
              </div>

        <div className="flex md:space-x-20 space-x-5">
          <div className="flex space-x-3 items-center">
            <span className="border border-[#73988d] p-2">
              <EnvelopeIcon className="h-6 w-6 text-[#73988d]" />
            </span>
            <div className="flex flex-col justify-center items-baseline">
              <p className="font-bold">Email us</p>
              <a href="mailto:kasbassnigenterprises@gmail.com" className="text-sm grey">Contact@ecogreen.com</a>
            </div>
          </div>
          <div className="hidden md:flex space-x-3 items-center">
            <span className="border border-[#73988d] p-2">
              <PhoneIcon className="h-6 w-6 text-[#73988d]" />
            </span>
            <div className="flex flex-col justify-center items-baseline">
              <p className="font-bold">Call us on</p>
              <a href="tel:+2348147603570" className="text-sm grey">(+00) 00 000 0000</a>
            </div>
          </div>
        </div>
      </div>
      <nav className="flex justify-center md:justify-between md:container items-center
        bg-[#6fb962] px-3  md:pr-0 text-white mx-auto text-[12px] md:text-[15px] font-bold">
        <ul className="flex space-x-4 md:space-x-10 md:mx-3 py-6">
          <NavLink className="link font-bold" to="/">HOME</NavLink>
          <li><NavLink className="link font-bold" to="/about">ABOUT US</NavLink></li>
          <li data-to-scrollspy-id="services"><NavLink className="link font-bold" to="/result">SERVICES</NavLink></li>
          <li><NavLink className="link font-bold" to="/">PROJECTS</NavLink></li>
          <li><NavLink className="link font-bold" to="/">FREE QUOTE</NavLink></li>
        </ul>
        <li className="hidden md:block hover:opacity-90 
        active:bg-[#5b8251] bg-[#73988d] px-5 py-6 font-bold">Contact</li>
      </nav>
    </header>
  );
}

export default Navbar;
