import { useNavigate } from 'react-router';
import bg2 from "../logo/bg2.jpg";

const AboutUs = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="rounded-md my-10 bg-white flex flex-col md:flex-row justify-between gap-10 md:mx-10 p-10 md:p-0">
        {/* Left section: Text */}
        <div className="w-full md:w-1/2 flex flex-col gap-6 p-10">
          <div className="text-left relative">
            <h2 className="font-bold text-[35px] text-[#5b8251] before:absolute before:bg-[#5b8251] before:h-2 before:w-20 before:-top-6">
              ABOUT US
            </h2>
          </div>
          <div className="text-left text-black space-y-4 leading-relaxed">
            <p className="font-bold text-[18px]">
              At Eco Green Project, we are committed to transforming the way you harness energy. 
            </p>
            <p>
              Founded by Sebastien, who fell in love with the breathtaking landscapes of Calp, our mission is to empower locals and expats to embrace sustainable living through solar energy solutions.
            </p>
            <p>
              Imagine reducing your electricity bill by an incredible 85%! Our solar panel installations are not only a smart choice, they are a game changer for your wallet and the environment.
            </p>
            <p>
              For over 15 years, we have been well established in the solar panel industry in Belgium, having successfully completed a number of installations. Today, we bring our expertise to the sunny coasts of Calp, Altea, Moraira, Benissa and beyond. Our full range of services includes:
            </p>
            <ul className="list-disc ml-5">
              <li>Solar panel installation: maximize your energy savings with our cutting-edge solar technology.</li>
              <li>Air conditioning solutions: keep your home cool and comfortable during the hot summer months.</li>
              <li>Pool Heat Pumps: Enjoy your pool all year round with energy-efficient heating solutions.</li>
              <li>Thermodynamic Hot Water Tanks: Harness renewable energy for your hot water needs.</li>
              <li>Electric Terminals: Ensure your home is equipped with the latest electrical solutions.</li>
            </ul>
            <p>
              Join us in our commitment to creating an eco-friendly environment while saving money. Let Eco Green Project guide you on your journey to sustainable living. Contact us today to find out how we can help you switch to solar energy and enjoy the benefits of a greener lifestyle!
            </p>
            <p className="font-bold text-[#5b8251]">Eco Green Project: Save Money, Save the Planet.</p>
          </div>
          
          {/* Button Section */}
          <div className="mt-6">
            <button 
              onClick={() => navigate('/contact')} 
              className="bg-[#5b8251] text-white py-3 px-6 rounded-md text-[20px] font-bold">
              MAKE AN ENQUIRY
            </button>
          </div>
        </div>

        {/* Right section: Image */}
        <div className="w-full md:w-1/2 flex justify-center p-0">
          <img
            src={bg2}
            alt="Eco Green Projects"
            className="rounded-md w-full h-full object-cover max-h-[700px]"
          />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
