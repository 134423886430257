import React from 'react';

const ContactButton = () => {

  return (
    <button 
      className="bg-[#5b8251] hover:bg-[#5b8251] text-white py-4 px-8 rounded-full shadow-lg transition-colors duration-300 text-lg font-semibold"
      style={{ 
        position: 'fixed', // Keep the button fixed
        bottom: '2rem',  // More padding from the bottom
        right: '4rem',   // More padding from the right
        zIndex: 10000
      }}
    >
      Contact Us
    </button>
  );
};

export default ContactButton;
