import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import './Result.css'
import solarinstallation from '../assets/solarpanelinstallation.jpg';
import heaterinstallation from '../assets/heaterinstallation.jpg';
import electricinstallation from '../assets/electricinstallation.jpg';
import airheaterinstallation from '../assets/airheaterinstallation.jpg';
import poolheaterinstallation from '../assets/poolheaterinstallation.jpg';

const Result = () => {
    return (
        <section className="bg-gray-100">
            <Navbar />

                <div className="services-container">
                    <h1 style={{textAlign: "center", lineHeight: "3", fontSize: "3rem", fontFamily: "ITC Avant Garde Gothic, sans-serif", fontWeight: "400", color: "#4a4a4a"}}>Our Services</h1>
                  {/* First Service: Plumbing & Gas */}
                  <div className="service-section">
                    <div className="service-content">
                      <div className="service-text">
                        <h2>Solar Panel Installation</h2><br></br>
                        <p><strong>At Eco Green Projects, we offer professional solar panel installation services to help you harness renewable energy and lower your energy bills.</strong></p><br></br>
                        <p>Our certified technicians provide expert installation, using high-quality solar panels to ensure maximum efficiency and minimal disruption to your daily routine.</p><br></br>
                        <p>Join the green energy revolution and make the switch to sustainable, cost-effective power today.</p>
                        <button className="service-button">View Our Solar Panel Installation Services</button>
                      </div>
                    </div>
                    <div className="service-image service-image-right">
                      <img
                        src={solarinstallation}
                        alt="Plumbing and Gas"
                      />
                    </div>
                  </div>

                  {/* Second Service: Drainage */}
                  <div className="service-section">
                    <div className="service-image service-image-left">
                      <img
                        src={heaterinstallation}
                        alt="Drainage Service"
                      />
                    </div>
                    <div className="service-content">
                      <div className="service-text">
                        <h2>Air Conditioning Installation Solutions</h2><br></br>
                        <p><strong>At Eco Green Projects, we provide professional air conditioning installation services to ensure optimal comfort for your home or business.</strong></p><br></br>
                        <p>Our certified HVAC team uses high-quality units and the latest technology to deliver efficient, energy-saving cooling solutions tailored to your needs.</p><br></br>
                        <p>Upgrade your indoor environment and enjoy a perfectly controlled climate all year round.</p>
                        <button className="service-button">View Our Air Conditioning Installation Services</button>
                      </div>
                    </div>
                  </div>

                  <div className="service-section">
                    <div className="service-content">
                      <div className="service-text">
                        <h2>Pool Heat Pump Installation</h2><br></br>
                        <p><strong>At Eco Green Projects, we offer professional pool heat pump installation to help you enjoy a comfortable swimming experience year-round.</strong></p><br></br>
                        <p>Our energy-efficient heat pumps are tailored to your pool’s size and needs, ensuring optimal performance and cost savings.</p><br></br>
                        <p>Upgrade your pool with eco-friendly heating solutions that reduce energy costs and extend your swimming season.</p>
                        <button className="service-button">View Our Pool Heat Pump Installation</button>
                      </div>
                    </div>
                    <div className="service-image service-image-right">
                      <img
                        src={poolheaterinstallation}
                        alt="Plumbing and Gas"
                      />
                    </div>
                  </div>


                  <div className="service-section">
                    <div className="service-image service-image-left">
                      <img
                        src={airheaterinstallation}
                        alt="Drainage Service"
                      />
                    </div>
                    <div className="service-content">
                      <div className="service-text">
                          <h2>Heat Pump Boiler Installation</h2><br></br>
                          <p><strong>At Eco Green Projects, we provide efficient and sustainable heat pump boiler installations for homes and businesses.</strong></p><br></br>
                          <p>Our certified professionals use state-of-the-art heat pump technology to reduce energy costs and minimize your carbon footprint.</p><br></br>
                          <p>Experience hassle-free installation and embrace energy-efficient heating that supports a greener future.</p>
                          <button className="service-button">View Our Heat Pump Boiler Installation</button>
                        </div>
                      </div>
                  </div>


                  <div className="service-section">
                    <div className="service-content">
                      <div className="service-text">
                        <h2>Electric Car Charging Station Installation</h2><br></br>
                        <p><strong>At Eco Green Projects, we specialize in installing electric vehicle (EV) charging stations to support sustainable transportation.</strong></p><br></br>
                        <p>Our expert team provides efficient, reliable installations with high-quality equipment, ensuring optimal performance and minimal disruption to your daily routine.</p><br></br>
                        <p>Upgrade to a greener future with our tailored EV charging solutions for home or business.</p>
                        <button className="service-button">View Our Electric Car Charging Station Installation</button>
                      </div>
                    </div>

                    <div className="service-image service-image-right">
                      <img
                        src={electricinstallation}
                        alt="Plumbing and Gas"
                      />
                    </div>
                  </div>
                </div>
             
            <Footer />
        </section>
        
    )
}

export default Result;