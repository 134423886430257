import React from 'react';
import bg2 from "../logo/bg1.jpg";

const WhoAreWe = () => {
  return (
    <section className="bg-white py-10 px-5 md:px-10">
      <div className="flex flex-col md:flex-row justify-between items-center gap-10">
        {/* Text Content */}
        <div className="w-full md:w-1/2 flex flex-col gap-5">
          <div className="text-center md:text-left relative">
            <h2 className="font-bold text-[30px] text-[#5b8251] before:absolute before:bg-[#5b8251] before:h-2 before:w-20 before:-top-6">
              Who Are We
            </h2>
            <p className="text-[18px] text-black">
              At Eco Green Project, we are dedicated to transforming the way you think about energy. 
              With a mission centered on saving money through solar energy while promoting eco-friendliness, 
              we strive to make sustainable living accessible to everyone. Our core values of innovation, 
              smart solutions, and sustainability guide us in every project we undertake.
            </p>
            <p className="text-[18px] text-black">
              With over 20 years of experience in the solar energy industry and 300 successful installations, 
              we have established ourselves as a trusted leader in the field. Our passion for renewable energy 
              is rooted in our love for Calp, a location blessed with 300 days of sunshine per year, making it 
              the perfect place for solar panel installations.
            </p>
            <p className="text-[18px] text-black">
              We offer a range of services, including solid installation and timely project completion, all 
              designed to help you save money while enjoying the benefits of clean energy. Our customer-focused 
              approach caters to both expats and locals who own homes in South Spain, ensuring that we meet the 
              unique needs of our diverse clientele.
            </p>
            <p className="text-[18px] text-black font-bold">
              Join us at Eco Green Project and take the first step towards a sustainable future. Together, we can 
              harness the power of the sun to create a cleaner, greener world.
            </p>
            <div className="pt-5">
              <button className="bg-[#5b8251] text-white font-bold py-2 px-6 rounded-md">
                VIEW OUR PROJECTS
              </button>
            </div>
          </div>
        </div>

        {/* Image */}
        <div className="w-full md:w-1/2">
          <img src={bg2} alt="Eco Green Projects" className="rounded-md w-full h-full object-cover max-h-[700px]" />
        </div>
      </div>
    </section>
  );
};

export default WhoAreWe;
