export const feedback = [
    {
   
      alt: "customer one",
      message: 'Eco Green Projects was fantastic! They were polite, efficient, and extremely professional. The installation of our solar panel system was seamless, and we are already seeing the benefits. Thanks to John and the entire team at Eco Green Projects!',
      name: "customer one",
      prof: "Chief Accountant",
    },
    {
      alt: 'customer two',
      message: `Eco Green Projects is the best solar installation company I’ve ever worked with. Their technicians are knowledgeable, professional, and reliable. We were extremely pleased with the results and would highly recommend them to anyone looking to switch to solar energy.`,
      name: 'customer two',
      prof: 'Secretary'
    },
    {
      alt: "customer three",  
      message: 'I am so grateful for the excellent work Eco Green Projects did on my home. They completed the solar installation efficiently and at a very reasonable price. Thanks for accommodating my schedule and ensuring everything was in perfect working order!',
      name: 'customer three',
      prof: 'Sales Manager'
    }
  ];
  