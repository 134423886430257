import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  MapIcon,
  MapPinIcon,
  PhoneIcon,
  EnvelopeIcon,
} from '@heroicons/react/24/solid';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa'; // React icons import
import logo from '../logo/ecologo.png'; // Ensure the logo path is correct

const Footer = () => {
  return (
    <section className='bg-[#5b8251] flex flex-col pt-4 pb-3 md:pb-0'>
      <div className='flex flex-col space-y-4 md:space-y-0 md:flex-row justify-around md:items-baseline items-center py-4'>
        
        {/* Logo section */}
        <div className='flex flex-col items-center md:items-start'>
          <h2 className='font-bold text-white mt-2'>ECO GREEN PROJECTS</h2>
          <img src={logo} alt="ECO Green Projects Logo" className='h-24 w-24' /> 
        </div>

       
        {/* Useful Links */}
        <div className='flex flex-col items-baseline'>
          <h2 className='font-bold text-white'>Useful Links</h2>
          <div className='flex flex-col items-baseline text-white px-2'>
            <NavLink className='link text-sm' to='/about'>About Us</NavLink>
            <NavLink className='link text-sm' to='/message'>Contact Us</NavLink>
            <NavLink className='link text-sm' to='/message'>Services</NavLink>
            <NavLink className='link text-sm' to='/projects'>Projects</NavLink>
            <NavLink className='link text-sm' to='/faqs'>FAQS</NavLink>
          </div>
        </div>
         {/* Contact Information */}
         <div className='flex flex-col space-y-1 items-baseline text-white font-bold'>
          <address className='flex items-baseline justify-center'>
            <div className='flex flex-col space-y-1 text-white'>
              <span className='text-sm flex items-center'>
                <MapIcon className='text-[#6fb962] h-4 w-4 mx-1' />
                france paris,
              </span>
              <span className='text-sm flex items-center'>
                <MapPinIcon className='text-[#6fb962] h-4 w-4 mx-1' />
                80 AAAAAAAAA Road, Paris
              </span>
              <span className='text-sm flex items-center'>
                <PhoneIcon className='text-[#6fb962] h-4 w-4 mx-1' />
                (+00) 00 000 0000
              </span>
              <div className='text-sm flex items-center'>
                <EnvelopeIcon className='text-[#6fb962] h-4 w-4 mx-1' />
                <a href='mailto:kasbassnigenterprises@gmail.com' className='text-white'>ECO GREEN PROJECTS@gmail.com</a>
              </div>
            </div>
          </address>
        </div>

      </div>

      {/* Social Media Links */}
      <div className='flex items-center justify-between space-y-1 p-3 connect px-6'>
        <h2 className='font-bold text-white text-[20px]'>Follow Us</h2>
        <div className='flex space-x-3'>
          <a href='https://facebook.com' target='_blank' rel='noreferrer'>
            <FaFacebook className='text-white h-6 w-6' />
          </a>
          <a href='https://twitter.com' target='_blank' rel='noreferrer'>
            <FaTwitter className='text-white h-6 w-6' />
          </a>
          <a href='https://instagram.com' target='_blank' rel='noreferrer'>
            <FaInstagram className='text-white h-6 w-6' />
          </a>
          <a href='https://linkedin.com' target='_blank' rel='noreferrer'>
            <FaLinkedin className='text-white h-6 w-6' />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Footer;
